import React from 'react';

function Contact() {
  return (
    <div className="footer">
      <a href="mailto:contact@vflu.net" className="contact" aria-label="Contact via Email">
        Contact
      </a>
    </div>
  );
}

export default Contact;