import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import logo from '../vflu_logo_1.svg';

function Logo() {
  const logoRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = useCallback((e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  }, []);

  const handleMouseLeave = useCallback(() => {
    setMousePosition({ x: 0, y: 0 });
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [handleMouseMove, handleMouseLeave]);

  const position = useMemo(() => {
    const logo = logoRef.current;
    if (!logo) return { x: 0, y: 0 };

    const rect = logo.getBoundingClientRect();
    const logoX = rect.left + rect.width / 2;
    const logoY = rect.top + rect.height / 2;

    const deltaX = mousePosition.x - logoX;
    const deltaY = mousePosition.y - logoY;

    const maxDistanceX = rect.width;
    const maxDistanceY = rect.height;

    if (Math.abs(deltaX) < maxDistanceX && Math.abs(deltaY) < maxDistanceY) {
      const angle = Math.atan2(deltaY, deltaX);
      const forceX = 1 - Math.abs(deltaX) / maxDistanceX;
      const forceY = 1 - Math.abs(deltaY) / maxDistanceY;
      const force = Math.min(forceX, forceY);

      const moveX = -Math.cos(angle) * force * 1000;
      const moveY = -Math.sin(angle) * force * 1000;

      return { x: moveX, y: moveY };
    }

    return { x: 0, y: 0 };
  }, [mousePosition]);

  return (
    <img
      ref={logoRef}
      src={logo}
      alt="VFLU Logo"
      className="logo"
      style={{
        transform: `translate(calc(-50% + ${position.x}px), calc(-50% + ${position.y}px))`,
      }}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = '/fallback-image.png';
      }}
    />
  );
}

export default Logo;