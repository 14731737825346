import React from 'react';
import './App.css';
import Logo from './components/Logo';
import Contact from './components/Contact';

function App() {
  return (
    <div className="container">
      <Logo />
      <Contact />
    </div>
  );
}

export default App;